import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "e-chart" }
const _hoisted_2 = {
  class: "e-chart__canvas",
  ref: "canvas",
  style: {"height":"100%","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512)
  ]))
}