
import { defineComponent, onMounted, PropType, ref, shallowRef } from 'vue'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    options: {
      type: Object as PropType<echarts.EChartsOption>,
    },
    initOpts: {
      type: Object as PropType<Parameters<typeof echarts.init>[2]>,
      default: undefined,
    },
    theme: {
      type: String,
      default: undefined,
    },
    events: {
      type: Array as PropType<Parameters<echarts.ECharts['on']>[]>,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const canvas = ref<HTMLElement>()
    const chart = shallowRef<echarts.EChartsType>()
    onMounted(() => {
      if (canvas.value === undefined) {
        console.error('The ECharts canvas is undefined')
        return
      }
      chart.value = echarts.init(canvas.value as HTMLElement, props.theme, props.initOpts)
      props.options && chart.value.setOption(props.options)

      for (const evt of props.events ?? []) {
        chart.value.on(...evt)
      }
      chart.value.on('click', (params) => {
        const dev = JSON.parse(JSON.stringify(props.options?.title)).text.split(' ')[1]
        const time = params.name.split('-')
        const cyclefrom = Number(time[0].split(':')[0]) * 60 + Number(time[0].split(':')[1])
        const cycleto = Number(time[1].split(':')[0]) * 60 + Number(time[1].split(':')[1])
        const q = { devCnt: dev, cyclefrom: cyclefrom, cycleto: cycleto }
        emit('plotClicked', q)
      })

      window.addEventListener('resize', () => {
        chart.value && chart.value.resize()
      })
    })

    return {
      canvas,
    }
  },
})
